import axios from 'axios';

let base_url = "https://api.konusarakingilizce.com/api/cms/"
let access_token = null;

export function post(path, data) {
    let config = {};
    
    if (access_token !== null) {
        config = {
            headers: {
                'Authorization': 'Bearer ' + access_token
            }
        }
    }

    return axios.post(base_url + path, data, config);
}

export function setAccessToken(token) {
    access_token = token;
}