import React from "react"
import { Navbar } from "reactstrap"
import { history } from "../../../history"
import { NavLink } from 'react-router-dom'
import { connect } from "react-redux"
import classnames from "classnames"
import {
  logoutWithJWT,
  logoutWithFirebase,
  logout
} from "../../../redux/actions/auth/loginActions"
import NavbarBookmarks from "./NavbarBookmarks"
import NavbarUser from "./NavbarUser"
import userImg from "../../../assets/img/portrait/small/avatar-s-11.jpg"
import navigationConfig from "../../../configs/navigationConfig"

const UserName = props => {
  let username = ""
  if (props.user.fullname !== undefined) {
    username = props.user.fullname
  } else {
    username = "John Doe"
  }

  return username
}
const ThemeNavbar = props => {
  const colorsArr = [ "primary", "danger", "success", "info", "warning", "dark"]
  const navbarTypes = ["floating" , "static" , "sticky" , "hidden"]

  const { user } = props
  let width = window.innerWidth

  if (width > 960) {
    return (
      <React.Fragment>
        <div className="content-overlay" />
        <div className="header-navbar-shadow" />
        <Navbar
          className={classnames(
            "header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow",
            {
              "navbar-light": props.navbarColor === "default" || !colorsArr.includes(props.navbarColor),
              "navbar-dark": colorsArr.includes(props.navbarColor),
              "bg-primary":
                props.navbarColor === "primary" && props.navbarType !== "static",
              "bg-danger":
                props.navbarColor === "danger" && props.navbarType !== "static",
              "bg-success":
                props.navbarColor === "success" && props.navbarType !== "static",
              "bg-info":
                props.navbarColor === "info" && props.navbarType !== "static",
              "bg-warning":
                props.navbarColor === "warning" && props.navbarType !== "static",
              "bg-dark":
                props.navbarColor === "dark" && props.navbarType !== "static",
              "d-none": props.navbarType === "hidden" && !props.horizontal,
              "floating-nav":
                (props.navbarType === "floating" && !props.horizontal) || (!navbarTypes.includes(props.navbarType) && !props.horizontal),
              "navbar-static-top":
                props.navbarType === "static" && !props.horizontal,
              "fixed-top": props.navbarType === "sticky" || props.horizontal,
              "scrolling": props.horizontal && props.scrolling
  
            }
          )}
        >
          <div className="navbar-wrapper">
            <div className="navbar-container content">
              <div
                className="navbar-collapse d-flex justify-content-between align-items-center"
                id="navbar-mobile"
              >
                <div className="bookmark-wrapper">
                  <NavbarBookmarks
                    sidebarVisibility={props.sidebarVisibility}
                    handleAppOverlay={props.handleAppOverlay}
                  />
                </div>
                {props.horizontal ? (
                  <div className="logo d-flex align-items-center">
                    <div className="brand-logo mr-50"></div>
                    <h2 className="text-primary brand-text mb-0">Vuexy</h2>
                  </div>
                ) : null}
                <NavbarUser
                  handleAppOverlay={props.handleAppOverlay}
                  changeCurrentLang={props.changeCurrentLang}
                  userName={<UserName {...props} />}
                  userImg={userImg}
                  loggedInWith={null}
                  logoutWithJWT={props.logoutWithJWT}
                  logoutWithFirebase={props.logoutWithFirebase}
                  logout={props.logout}
                />
              </div>
            </div>
          </div>
        </Navbar>
      </React.Fragment>
    )
  } else {
    if (user.role == "trainer") {
      return (
        <div style={{ width: "100%", overflow: 'scroll', marginBottom: 20 }}>
          <div style={{ width: (width / 3) * navigationConfig.filter(x => x.permissions.includes(user.role)).length, height: 48 }}>
          {
          navigationConfig.filter(x => x.permissions.includes(user.role)).map((item, index) => {
            return (
              <NavLink to={item.navLink} style={{ backgroundColor: 'transparent', height: 48, textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', border: 0, fontSize: '12px', fontWeight: '700', width: width / 3, float: 'left' }} key={index}>{item.title}</NavLink>
            )
          })
          }
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.login.data
  }
}

export default connect(mapStateToProps, {
  logoutWithJWT,
  logoutWithFirebase,
  logout
})(ThemeNavbar)
