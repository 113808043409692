import React from "react"
import * as Icon from "react-feather"
const navigationConfig = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["admin", "editor","system-admin", "trainer"],
    navLink: "/",
  },
  {
    id: "definitions",
    title: "Tanımlar",
    type: "collapse",
    icon: <Icon.Layers size={20} />,
    permissions: ["admin","system-admin"],
    children: [
      {
        id: "countries",
        title: "Ülkeler",
        icon: <Icon.Circle size={12} />,
        type: "item",
        permissions: ["admin","system-admin"],
        navLink: "/countries"
      },          
      {
        id: "levels",
        title: "Seviyeler",
        icon: <Icon.Circle size={12} />,
        type: "item",
        permissions: ["admin","system-admin"],
        navLink: "/levels"
      }, 
      {
        id: "interests",
        title: "İlgi Alanları",
        icon: <Icon.Circle size={12} />,
        type: "item",
        permissions: ["admin","system-admin"],
        navLink: "/interests"
      }, 
      {
        id: "professions",
        title: "Meslekler",
        icon: <Icon.Circle size={12} />,
        type: "item",
        permissions: ["admin","system-admin"],
        navLink: "/professions"
      }, 
      {
        id: "units",
        title: "Birimler",
        icon: <Icon.Circle size={12} />,
        type: "item",
        permissions: ["admin","system-admin"],
        navLink: "/units"
      }, 
    ]
  },
  {
    id: "contents",
    title: "İçerik Yönetimi",
    type: "collapse",
    icon: <Icon.Layout size={20} />,
    permissions: ["admin","system-admin"],
    children: [
      // {
      //   id: "source-types",
      //   title: "Konu Tipleri",
      //   icon: <Icon.Circle size={12} />,
      //   type: "item",
      //   permissions: ["admin","system-admin"],
      //   navLink: "/source-types"
      // },       
      {
        id: "sources",
        title: "Konular",
        icon: <Icon.Circle size={12} />,
        type: "item",
        permissions: ["admin","system-admin"],
        navLink: "/sources"
      }, 
      // {
      //   id: "source-details",
      //   title: "Konu İçerikleri",
      //   icon: <Icon.Circle size={12} />,
      //   type: "item",
      //   permissions: ["admin","system-admin"],
      //   navLink: "/source-details"
      // },             
      {
        id: "main-content-groups",
        title: "Anasayfa İçerik Grupları",
        icon: <Icon.Circle size={12} />,
        type: "item",
        permissions: ["admin","system-admin"],
        navLink: "/main-content-groups"
      }, 
      {
        id: "package-values",
        title: "Paket Seçim Değerleri",
        icon: <Icon.Circle size={12} />,
        type: "item",
        permissions: ["admin","system-admin"],
        navLink: "/package-values"
      },                
      {
        id: "main-contents",
        title: "Anasayfa İçerikleri",
        icon: <Icon.Circle size={12} />,
        type: "item",
        permissions: ["admin","system-admin"],
        navLink: "/main-contents"
      }, 
      // {
      //   id: "files",
      //   title: "Dosya Yönetimi",
      //   icon: <Icon.Circle size={12} />,
      //   type: "item",
      //   permissions: ["admin","system-admin"],
      //   navLink: "/files"
      // },       
      {
        id: "exercises",
        title: "Dinleme Egzersizleri",
        icon: <Icon.Circle size={12} />,
        type: "item",
        permissions: ["admin","system-admin"],
        navLink: "/exercises"
      }, 
      {
        id: "blogs",
        title: "Blog İçerikleri",
        icon: <Icon.Circle size={12} />,
        type: "item",
        permissions: ["admin","system-admin"],
        navLink: "/blogs"
      },       
      {
        id: "help-text",
        title: "Yardım Metni",
        icon: <Icon.Circle size={12} />,
        type: "item",
        permissions: ["admin","system-admin"],
        navLink: "/help-text"
      }, 
      {
        id: "privacy-text",
        title: "Kişisel Verileri Koruma İle İlgili Bilgilendirme",
        icon: <Icon.Circle size={12} />,
        type: "item",
        permissions: ["admin","system-admin"],
        navLink: "/privacy-text"
      }, 
      {
        id: "user-text",
        title: "Kişisel Verileri Koruma Formu",
        icon: <Icon.Circle size={12} />,
        type: "item",
        permissions: ["admin","system-admin"],
        navLink: "/user-text"
      },
      {
        id: "sales-contract",
        title: "Medu Bilişim Eğitim Sözleşmesi",
        icon: <Icon.Circle size={12} />,
        type: "item",
        permissions: ["admin","system-admin"],
        navLink: "/sales-contract"
      }, 
      {
        id: "preliminary-text",
        title: "Ön Bilgilendirme Formu ve Mesafeli Satış Sözleşmesi",
        icon: <Icon.Circle size={12} />,
        type: "item",
        permissions: ["admin","system-admin"],
        navLink: "/preliminary-text"
      },  
      {
        id: "delivery-policy",
        title: "Cayma İptal İade Koşulları",
        icon: <Icon.Circle size={12} />,
        type: "item",
        permissions: ["admin","system-admin"],
        navLink: "/delivery-policy"
      },            
    ]
  },
  {
    id: "package-management",
    title: "Ürün Yönetimi",
    type: "collapse",
    icon: <Icon.Box size={20} />,
    permissions: ["admin","system-admin"],
    children: [
      {
        id: "package-categories",
        title: "Paket Kategorileri",
        icon: <Icon.Circle size={12} />,
        type: "item",
        permissions: ["admin","system-admin"],
        navLink: "/package-categories"
      },          
      {
        id: "packages",
        title: "Paketler",
        icon: <Icon.Circle size={12} />,
        type: "item",
        permissions: ["admin","system-admin"],
        navLink: "/packages"
      }, 
      {
        id: "coupons",
        title: "Kuponlar",
        icon: <Icon.Circle size={12} />,
        type: "item",
        permissions: ["admin","system-admin"],
        navLink: "/coupons"
      }, 
      {
        id: "discounts",
        title: "İndirimler",
        icon: <Icon.Circle size={12} />,
        type: "item",
        permissions: ["admin","system-admin"],
        navLink: "/discounts"
      }
    ]
  },
  {
    id: "user-management",
    title: "Kullanıcı Yönetimi",
    type: "collapse",
    icon: <Icon.Users size={20} />,
    permissions: ["admin", "editor","system-admin"],
    children: [
      {
        id: "app-users",
        title: "Uygulama Kullanıcıları",
        icon: <Icon.Circle size={12} />,
        type: "item",
        permissions: ["admin", "editor","system-admin"],
        navLink: "/app-users"
      },          
      {
        id: "trainers",
        title: "Eğitmenler",
        icon: <Icon.Circle size={12} />,
        type: "item",
        permissions: ["admin","system-admin"],
        navLink: "/trainers"
      }, 
      {
        id: "managers",
        title: "Panel Kullanıcıları",
        icon: <Icon.Circle size={12} />,
        type: "item",
        permissions: ["admin","system-admin"],
        navLink: "/managers"
      }
    ]
  }, 
  {
    id: "sells-management",
    title: "Satış Yönetimi",
    type: "item",
    icon: <Icon.BarChart size={20} />,
    permissions: ["system-admin"],
    navLink: "/sells"
  }, 
  {
    id: "notifications",
    title: "Bildirim Gönder",
    type: "item",
    icon: <Icon.Send size={20} />,
    permissions: ["system-admin"],
    navLink: "/send-notification"
  }, 
  {
    id: "my-calendar",
    title: "My Calendar",
    type: "item",
    icon: <Icon.Calendar size={20} />,
    permissions: ["trainer"],
    navLink: "/my-calendar"
  }, 
  {
    id: "my-trials",
    title: "My Trials",
    type: "item",
    icon: <Icon.Phone size={20} />,
    permissions: ["trainer"],
    navLink: "/my-trials"
  }, 
  // {
  //   id: "available-days",
  //   title: "Ders Planım",
  //   type: "item",
  //   icon: <Icon.CheckCircle size={20} />,
  //   permissions: ["trainer"],
  //   navLink: "/my-plan"
  // }, 
  // {
  //   id: "add-lesson",
  //   title: "Yeni Plan Ekle",
  //   type: "item",
  //   icon: <Icon.PlusCircle size={20} />,
  //   permissions: ["trainer"],
  //   navLink: "/add-plan"
  // }, 
  // {
  //   id: "edit-lesson",
  //   title: "Plan Güncelle",
  //   type: "item",
  //   icon: <Icon.Edit3 size={20} />,
  //   permissions: ["trainer"],
  //   navLink: "/edit-plan/:uid?",
  //   filterBase: "/edit-plan/0"
  // },     
  {
    id: "settings",
    title: "Genel Ayarlar",
    type: "item",
    icon: <Icon.Settings size={20} />,
    permissions: ["admin", "system-admin"],
    navLink: "/general-settings",
  },
  {
    id: "waiting-lessons",
    title: "Ders Tanımla",
    type: "item",
    icon: <Icon.Monitor size={20} />,
    permissions: ["admin", "system-admin", "editor"],
    navLink: "/waiting-lessons",
  },
  {
    id: "postpone-requests",
    title: "Erteleme Talepleri",
    type: "item",
    icon: <Icon.Monitor size={20} />,
    permissions: ["admin", "system-admin", "editor"],
    navLink: "/postpone-requests",
  },  
  {
    id: "trials",
    title: "Eğitim Dersi Talepleri",
    type: "item",
    icon: <Icon.Target size={20} />,
    permissions: ["admin", "system-admin", "editor"],
    navLink: "/trials",
  },
  {
    id: "contact-forms",
    title: "Yardım Formları",
    type: "item",
    icon: <Icon.Filter size={20} />,
    permissions: ["admin", "system-admin", "editor"],
    navLink: "/forms",
  },
  {
    id: "newsletters",
    title: "Haber Bülteni Kayıtları",
    type: "item",
    icon: <Icon.Filter size={20} />,
    permissions: ["admin", "system-admin", "editor"],
    navLink: "/newsletters",
  },  
  {
    id: "comments",
    title: "Kullanıcı Yorumları",
    type: "item",
    icon: <Icon.MessageCircle size={20} />,
    permissions: ["admin", "system-admin", "editor"],
    navLink: "/comments",
  },
  // {
  //   id: "account-settings",
  //   title: "Hesap Bilgilerim",
  //   type: "item",
  //   icon: <Icon.Settings size={20} />,
  //   permissions: ["admin", "system-admin", "editor", "trainer"],
  //   navLink: "/account-settings",
  // },
  {
    id: "change-password",
    title: "Şifre Değiştirme",
    type: "item",
    icon: <Icon.Lock size={20} />,
    permissions: ["admin", "system-admin", "editor"],
    navLink: "/change-password",
  },
  {
    id: "change-password-en",
    title: "Change Password",
    type: "item",
    icon: <Icon.Lock size={20} />,
    permissions: ["trainer"],
    navLink: "/change-password-en",
  },
  {
    id: "logout",
    title: "Çıkış",
    type: "item",
    icon: <Icon.LogOut size={20} />,
    permissions: ["admin", "system-admin", "editor"],
    navLink: "/logout",
  },
  {
    id: "logout-en",
    title: "Logout",
    type: "item",
    icon: <Icon.LogOut size={20} />,
    permissions: ["trainer"],
    navLink: "/logout-en",
  }
]

export default navigationConfig
